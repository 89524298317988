/*  --- Variable ---  */

$font-size: 16px;
$header: 106px;

//COLOR

$dark: #231F20;
$white: #ffffff;
$cream: #FDECD7;
$yellow: #FEBC18;
$green: #006257;
$dark_green: #004840;
