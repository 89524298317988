
// General page style
.template-producteur {
  background-color: $dark_green;


  .container {
    max-width: 1300px;
  }

  .section1 {
    padding-top: 100px;
    position: relative;
    @include break(tablet) {
      padding-top: 80px;
    }

    img {
      width: 100%;
      height: auto;
    }

    svg.icon_plus {
      width: 90px;
      height: auto;
      position: absolute;
      top: 59.5%;
      left: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;
      transform-origin: center;
      z-index: 9;
      @include break(tablet) {
        top:33%;
        width: 75px;
      }
    }

    .txt_wrapper {
      background-color: $white;
      width: 600px;
      height: 600px;
      border-radius: 50%;
      padding: 100px;
      color: $green;
      @include absolute-center(center)
      position: absolute;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      @include font-titre-medium;
      font-size: 1.2em;
      opacity: 0;
      display: none;
      .inner {
        opacity: 0;
      }
      @include break(tablet) {
        width: 280px;
        height: 280px;
        font-size: 0.8em;
        padding: 50px;
      }

      blockquote {
        font-size: 1.5em;
        @include font-titre-bold;
        padding-bottom: 20px;
        @include break(tablet) {
          font-size: 1.2em;
        }
      }
    }
  }

  .prod {
    .content_wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include break(small-screen) {
        flex-direction: column;
      }
      >div {
        width: 50%;
        @include break(small-screen) {
          width: 100%;
        }

        &.content {
          padding-left: 5%;
          padding-right: 5%;
          color: $cream;
          @include break(small-screen) {
            padding-top: 40px;
            padding-bottom: 40px;
          }
          @include break(tablet) {
            padding-left: 0%;
            padding-right: 0%;
          }

          h2 {
            padding-bottom: 35px;
          }
        }

        &.anim {
          -webkit-touch-callout: none; /* iOS Safari */
          -webkit-user-select: none; /* Safari */
           -khtml-user-select: none; /* Konqueror HTML */
             -moz-user-select: none; /* Old versions of Firefox */
              -ms-user-select: none; /* Internet Explorer/Edge */
                  user-select: none;
          position: relative;
          max-width: 680px;
          @include break(tablet) {
            display: flex;
            flex-direction: column-reverse;
            margin-bottom: 80px;
          }
          >svg {
            width: 100%;
            height: auto!important;
          }
          input[type=range]{
            -webkit-appearance: none;
            background: transparent;
            background-image: url(../img/range_bg.svg);
            width: 240px;
            background-repeat: no-repeat;
            background-size: contain;
            outline: none;
            position: absolute;
            left: 50%;
            bottom: 125px;
            transform: translateX(-50%);
            z-index: 9;
            @include break(tablet) {
              transform: none;
              position: relative;
              left: auto;
              bottom: auto;
              margin-left: auto;
              margin-right: auto;
            }

            &::-webkit-slider-thumb {
              -webkit-appearance: none;
              appearance: none;
              width: 62px;
              height: 62px;
              background-image: url(../img/range_handle.svg);
              border-radius: 50%;
              background-repeat: no-repeat;
              background-size: contain;
              margin-top: -20px;
              cursor: grab;
              &:active {
                cursor: grabbing;
              }
            }
            &:focus {
              outline: none;
            }
          }
        }
      }
    }

    .path_sec {
      @include break(tablet) {
        display: none!important;
      }
    }

    &:nth-child(odd) .content_wrap {
      @include break(small-screen) {
        flex-direction: column-reverse;
      }
    }
  }

  .bulle_wrap {
    position: relative;

    .bulle {
      position: absolute;
      top: 48%;
      left: 50%;
      width: 280px;
      height:280px;
      border-radius: 50%;
      background-color: $yellow;
      text-align: center;
      padding: 30px;
      color: $dark_green;
      z-index: 10;
      transform-origin: center center;
      transform: translate(-50%,-50%) scale(0.2);
      @include break(tablet) {
        position: relative;
        margin-top: 80px;
        margin-bottom: 80px;
        transform: scale(0.2);
        margin-left: auto;
        margin-right: auto;
        top: 0;
        left: 0;
      }

      .inner {
        opacity: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      // strong {
      //   font-size: 4em;
      //   display: block;
      //   @include font-titre-bold;
      // }
      b {
        font-size: 1.6em;
        display: block;
        padding-bottom: 15px;
        @include font-titre-bold;
      }
      small {
        font-size: 0.875em;
        display: block;
        text-transform: uppercase;
        @include font-titre-medium;
      }
    }
  }


  // Specific style

  .section2 {
    margin-top: -100px;
    @include break(tablet) {
      margin-top: 0;
    }

    .path_sec {
      margin-left: auto;
      margin-right: 25%;
      display: block;
      margin-bottom: -50px;
      @include break(small-screen) {
        margin-bottom: 50px;
      }
      g.g_anime {
        stroke-dasharray: 835px;
        stroke-dashoffset: 835px;
      }
    }

    .anim svg {
      @include break(small-screen) {
        width: 108%!important;
        margin-left: -4%;
      }
    }
  }

  .section3 {
    .path_sec {
      margin-top: -50px;
      margin-bottom: -50px;
      margin-left: auto;
      margin-right: 25%;
      display: block;

      @include break(small-screen) {
        margin-top: 50px;
        margin-bottom: 155px;
        margin-right: 18%;
        transform: rotate(-38deg);
        z-index: 7;
        position: relative;
      }

      g.g_anime {
        stroke-dasharray: 1200px;
        stroke-dashoffset: 1200px;
      }
    }

    .anim input[type=range]{
      bottom: 75px!important;
      @include break(tablet) {
        bottom: 0!important;
        margin-top: 25px;
      }
    }
  }

  .section4 {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none;
    .path_sec {
      margin-top: -50px;
      margin-bottom: -50px;
      margin-left: 25%;
      display: block;
      @include break(small-screen) {
        margin-top: 155px;
        margin-bottom: 153px;
        transform: rotate(38deg);
      }
      g.g_anime {
        stroke-dasharray: 685px;
        stroke-dashoffset: 685px;
      }
    }
    .anim {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none;
    }

    .anim .pgq_step3_bg {
      @include break(small-screen) {
        width: 108%!important;
        max-width: 108%!important;
        margin-left: -4%;
        -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none;
      }
    }

    .pgq_step3 {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none;
    }

    .content_wrap > div.anim.pgq_step3 .pompe {
      position: absolute;
      width: 250px;
      top: 90px;
      left: 80px;
      @include break(tablet) {
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
         -khtml-user-select: none; /* Konqueror HTML */
           -moz-user-select: none; /* Old versions of Firefox */
            -ms-user-select: none; /* Internet Explorer/Edge */
                user-select: none;
        width: 200px;
        top: 20px;
        left:20px;
      }
      .pompe_svg {
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
         -khtml-user-select: none; /* Konqueror HTML */
           -moz-user-select: none; /* Old versions of Firefox */
            -ms-user-select: none; /* Internet Explorer/Edge */
                user-select: none;
        width: 100%;
        #liquide{
          opacity:0;
        }
      }
      .cursor_icon {
        position: absolute;
        top: 0px;
        left: 80px;
        width: 62px;
        height: auto;
        pointer-events: none;
        @include break(tablet) {
          top: -4px;
          left: 48px;
          width: 50px;
        }
      }
      cursor: grab;
      &:active {
        cursor: grabbing;
      }
    }

    .bug {
      width: 25px;
      padding: 15px;
      box-sizing: content-box;
      position: absolute;
      bottom: 0px;
      left: 0px;
      opacity: 1;

      &1 {
        bottom: 42%;
        left:8%;
      }
      &2 {
        bottom: 29%;
        left: 21%;
      }
      &3 {
        width: 30px;
        bottom: 20%;
        left: 26%;
      }
      &4 {
        bottom: 35%;
        left: 57%;
      }
      &5 {
        bottom: 18%;
        right: 26%;
        left: auto;
      }
      &6 {
        width: 30px;
        bottom: 32%;
        right: 13%;
        left: auto;
      }
    }
  }

  .section5 {
    .path_sec {
      margin-top: -130px;
      margin-bottom: -145px;
      margin-left: auto;
      margin-right: 42%;
      display: block;
      @include break(small-screen) {
        margin-top: -5px;
        margin-bottom: 50px;
        transform: rotate(-49deg);
      }
      g.g_anime {
        stroke-dasharray: 700px;
        stroke-dashoffset: 700px;
      }
    }

    .anim svg {
      @include break(small-screen) {
        width: 108%!important;
        margin-left: -4%;
      }
    }

    .content_wrap >div.anim input[type=range]{
      left: 4%;
      transform: rotate(-90deg);
      bottom: 250px;
      @include break(tablet) {
        bottom: 0;
        left: 0;
        margin-top: 20px;
        transform: none;
      }
    }
  }

  .section6 {
    .path_sec {
      margin-top: -50px;
      margin-bottom: -40px;
      margin-left: 25%;
      display: block;
      z-index: 9;
      position: relative;
      @include break(small-screen) {
        margin-top: 130px;
        margin-bottom: 200px;
        transform: rotate(45deg);
      }
      g.g_anime {
        stroke-dasharray: 720px;
        stroke-dashoffset: 720px;
      }
    }

    .anim svg {
      @include break(small-screen) {
        width: 108%!important;
        margin-left: -4%;
      }
    }
  }

  .section7 {
    .content_wrap {
      align-items: flex-start;
    }
    .path_sec {
      margin-top: -130px;
      margin-bottom: -30px;
      margin-left: 26%;
      display: block;
      @include break(small-screen) {
        margin-top: -29px;
        margin-left: 10%;
        margin-bottom: 50px;
        transform: rotate(-10deg);
      }
      g.g_anime {
        stroke-dasharray: 1160px;
        stroke-dashoffset: 1160px;
      }
    }

    .bulle_wrap .bulle {
      top: 54%;
    }

    .anim svg {
      width: 415px !important;
      margin-top: -20px;
      margin-bottom: -110px;
      margin-left: 41%;
      margin-left: 43%;
      @include break(small-screen) {
        width: 335px !important;
        margin-bottom: -75px;
        margin-left: 28%;
        margin-top: 0px;
        transform: rotate(15deg)!important;
      }
      @include break(tablet) {
        margin-bottom: -160px;
        margin-left: 10%;
        margin-top: 8px;
        transform: rotate(27deg)!important;
      }
    }
  }

  .section8 {
    padding-top: 0px;
    padding-bottom: 200px;
    @include break(tablet) {
      padding-bottom: 100px;
    }
    .anim {
      margin-left: auto;
      margin-right: auto;
      width: 60%;
      @include break(tablet) {
        width: 100%;
      }
    }
  }

  .section9 {
    padding-top: 80px;
    padding-bottom: 80px;

    .container {
      max-width: 1000px;
    }

    .faq {
      padding-top: 40px;
      padding-bottom: 40px;
      display: flex;
      justify-content: space-between;
      @include break(mobile){
        flex-direction: column;
      }

      .box-faq {
        background-color: $cream;
        @include break(mobile){
          padding-bottom: 40px;
        }
      }
    }

    .bottom {
      display: block;
      text-align: center;
    }
  }

}
