html{
  overflow-x: hidden;
  overflow-y: scroll;
  &.overflow{
    overflow: hidden;
  }
  background-color: $cream;
  color: $dark;
  &::-webkit-scrollbar { width: 0 !important };
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
}

body {
  &.overflow{
    overflow: hidden;
  }
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  background-color: $cream;
  color: $dark;
  font-size: $font-size;
  line-height: 1.375em;
  white-space: normal;
  @include font-regular;
  @include break(tablet) {
    overflow-x: hidden;
    overflow-y: scroll;
  }
  @include break(mobile) {
    overflow: hidden;
  }
}

.container {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  max-width: 1000px;
}

.desktop {
  @include break(mobile) {
    display: none;
  }
}
.mobile {
  display: none;
  @include break(mobile) {
    display: initial;
  }
}

.down-tablet {
  display: none;
  @include break(down-tablet) {
    display: initial;
  }
}
.desk-tablet {
  @include break(tablet) {
    display: none;
  }
}
.tablet {
  display: none;
  @include break(tablet) {
    display: initial;
  }
}

a {
  color: inherit;
  transition: all 0.4s;
  display: inline;
  line-height: 1em;;
  position: relative;
  &:visited {
    color: inherit;
  }

  &.link {
    @include font-titre-bold;
    color: $green;
    font-size: 1.25em;
    text-decoration: none;
    position: relative;

    &:after {
      content: " ";
      background-color: $green;
      height: 2px;
      width: 100%;
      position: absolute;
      bottom:-15px;
      left: 0;
    }
  }
}

.btn {
  border-radius: 40px;
  text-decoration: none;
  font-size: 1.25em;
  @include font-titre-bold;
  line-height: 1em;
  vertical-align: middle;
  padding: 20px 40px;
  display: inline-block;
  text-align: center;
  position: relative;
  overflow: hidden;
  z-index: 1;
  @include break(mobile){
    font-size: 1em;
    padding: 15px 14px;
    max-width: 290px;
    font-size: 0.9em;
  }

  &:after {
    content: ' ';
    width: 180%;
    padding-bottom: 180%;
    border-radius: 50%;
    display: block;
    position: absolute;
    bottom: 121%;
    left: -100%;
    transform-origin: bottom left;
    transition: transform $ease-in-out 0.4s, color $ease-in-out 0.4s;
    z-index: -1;
  }

  &:hover:after {
    @include break(desktop-and-tablet) {
      transform: rotate(45deg);
    }
  }

  &.btn_nobg_green {
    border: 2px solid $green;
    color: $green;

    &:after {
      background-color: $green;
    }

    &:hover {
      @include break(desktop-and-tablet) {
        color: $cream;
      }
    }
  }

  &.btn_nobg_white {
    border: 2px solid $white;
    color: $white;

    &:after {
      background-color: $white;
    }

    &:hover {
      color: $green;
    }
  }

  &.btn_cream {
    border: 2px solid $cream;
    background-color: $cream;
    color: $green;

    &:after {
      background-color: $green;
    }

    &:hover {
      color: $cream;
    }

    &:hover:after {
      color: $cream;
    }
  }

  &.mobile {
    display: none;
    @include break(mobile) {
      display: inline-block;
    }
  }
}

.txt-green {
  color: $green;
}
.bg-green {
  background-color: $green;
}

.txt-white {
  color: $white;
}
.bg-white {
  background-color: $white;
}

.txt-cream {
  color: $cream;
}
.bg-cream {
  background-color: $cream;
}

p {
  line-height: 1.2em;
}

h1 {
  @include font-titre-bold;
  font-size: 3.75rem;
  line-height: 1.05em;
}
h1.small {
  font-size: 2.8rem;
  line-height: 1.05em;
}

h2 {
  @include font-titre-bold;
  font-size: 2.8rem;
  line-height: 1.05em;
}

h3{
  @include font-titre-bold;
  font-size: 2.8rem;
  line-height: 1.05em;
}

h4{
  @include font-titre-medium;
  font-size: 1.56rem;
  line-height: 1.05em;
}

.legal-notices {
  padding-top: 150px;
  @include break(mobile) {
    padding-top: 125px;
    h1 {
      font-size: 2.5rem;
    }
  }
}
