.template-fermes {
  .section1 {
    height: 120vh;
    background-position: center;
    background-size: cover;
    text-align: center;
    color: $white;
    position: relative;

    h1 {
      position: absolute;
      top: calc(50% - 20vh);
      left: 50%;
      transform: translate(-50%,-50%);
      max-width: 600px;
      width: 90%;
      font-size: 3.75em;
      @include break(mobile){
        top: calc(50% - 13vh);
        font-size: 3em;
      }
    }

    .gradient {
      background: linear-gradient(0deg, rgba(253,236,215,1) 0%, rgba(253,236,215,1) 15%, rgba(253,236,215,0.8) 65%, rgba(253,236,215,0) 100%);
      position: absolute;
      height: 30vh;
      bottom: -1px;
      left: 0;
      width: 100%;
    }
  }

  .section2 {
    padding-top: 120px;
    padding-bottom: 120px;

    .container {
      display: flex;
      align-items: center;
      @include break(mobile){
        flex-direction: column;
      }
      >div:first-of-type {
        width: 60%;
        padding-right: 5%;
        @include break(mobile){
          width: 100%;
          padding-right: 0;
        }
        h2 {
          color: $green;
          padding-bottom: 40px;
        }
      }

      .sec2_slider {
        width: 40%;
        position: relative;
        @include break(mobile){
          width: 100%;
          padding-top: 40px;
        }
        .wrapper {
          background-color: $green;
          color: $cream;
          border-radius: 50%;
          width: 300px;
          height: 300px;
          overflow: hidden;
          position: relative;
          margin-left: auto;
          margin-right: auto;

          div {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-align: center;
            opacity: 0;
            width: 90%;

            &:first-of-type {
              opacity: 1;
            }

            .sup {
              text-transform: uppercase;
              @include font-titre-medium;
              font-size: 0.875em;
              letter-spacing: 2px;
              padding-left: 10%;
              padding-right: 10%;
            }

            .big {
              @include font-titre-bold;
              font-size: 2.7em;
              padding-top: 10px;
              padding-bottom: 10px;
            }

            .sub {
              font-size: 1em;
              padding-left: 10%;
              padding-right: 10%;
            }
          }
        }

        .slider_nav {
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          color: $green;
          display: flex;
          flex-direction: column;
          align-items: center;
          @include break(mobile){
            flex-direction: row;
            top: auto;
            transform: translateY(0 )translateX(-50%);
            bottom:-70px;
            width: 70%;
            left: 50%;
            justify-content: space-between;
          }

          .slider_number div {
            display: inline-block;

            &.wrapper_number {
              position: relative;
              width: 17px;
              height: 16px;
              overflow: hidden;
              margin-right: -2px;

              span {
                position: absolute;
                top: -100%;
                right: 0;

                &.init {
                  top: 0;
                }
              }
            }
          }

          .arrow {
            width: 12px;
            height: 60px;
            cursor: pointer;
            @include break(mobile) {
              width: 20px;
              margin-left: 20px;
              margin-right: 20px;
              box-sizing: content-box;
            }

            &.arrow-top {
              transform: rotate(180deg);
              @include break(mobile){
                transform: rotate(90deg);
              }
            }
            &.arrow-bottom {
              @include break(mobile){
                transform: rotate(-90deg);
              }
            }
          }
        }
      }
    }

  }


  .section3 {
    height: 900px;
    position: relative;
    overflow: hidden;
    @include break(mobile){
      height: 700px;
    }

    h2 {
      font-size: 2.25em;
      color: $green;
      position: absolute;
      top: 100px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 999;
    }

    .slider_season_image {
      width: 100%;
      height: 100%;
      position: relative;

      .image {
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: bottom center;
        position: absolute;
        transform: scale(1.04);
        top: 0;
        left: 0;
        opacity: 0;

        &:first-of-type {
          opacity: 1;
        }
      }
    }

    .bottom {
      position: absolute;
      bottom:0;
      z-index: 9;
      left: 0;
      width: 100%;
      color: $green;
      @include break(mobile){
        padding-bottom: 40px;
      }

      .arrow {
        width: 12px;
        cursor: pointer;
        padding-top: 20px;
        padding-bottom: 20px;

        &.arrow-left {
          transform: rotate(90deg);
        }
        &.arrow-right {
          transform: rotate(-90deg);
        }
      }

      .slider_season_nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 70px;
        z-index: 2;
        position: relative;

        .season {
          cursor: pointer;
          border: 2px solid $green;
          border-radius: 20px;
          padding: 5px 12px;
          @include break(mobile) {
            padding: 2px 5px;
            font-size: 13px;
          }
        }
      }

      .slider_season_text {
        position: relative;
        height: 150px;
        z-index: 1;
        @include break(mobile) {
          height: 185px;
          font-size: 14px;
        }

        >div {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          opacity: 0;
          text-align: center;
        }
      }

      .bg {
        width: 100%;
        height: 130%;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: -1;
        background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 3%, rgba(255,255,255,0.7) 65%, rgba(255,255,255,0) 100%);
      }
    }
  }

  .section4 {
    padding-top: 80px;
    padding-bottom: 80px;

    .text {
      width: 60%;
      @include break(mobile){
        width: 100%;
      }

      h2 {
        font-size: 2.8em;
        padding-bottom: 30px;
      }

      >div {
        font-size: 1.125em;
        padding-bottom: 35px;
      }

      .btn {
        @include break(mobile){
          max-width: 290px;
          margin-left: auto;
          margin-right: auto;
          display: block;
          padding: 15px 14px;
          white-space: normal;
        }
      }
    }

    .liste_wrap {
      display: flex;
      align-items: center;
      @include break(mobile){
        flex-direction: column;
        padding-top: 40px;
      }
      >div {
        width: 50%;
        @include break(mobile){
          flex-direction: column;
          width: 100%;
        }
      }

      .liste {
        display: flex;
        opacity: 0.5;
        cursor: pointer;
        transition: opacity 0.4s;
        @include break(mobile){
          padding-bottom: 20px;
        }

        .liste-icon {
          width: 75px;
          height: 75px;
          border-radius: 50%;
          position: relative;
          margin-right: 25px;
          @include break(mobile){
            width: 60px;
            height: 60px;
          }

          img {
            width: 90%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
          }
        }

        .liste-text {
          width: calc(100% - 100px);
          padding-right: 20%;
          @include break(mobile){
            padding-right: 0;
          }
        }

        &.liste1 .liste-icon {
          background-color: $yellow;
        }

        &.liste2 .liste-icon {
          background-color: $white;
        }

        &.liste3 .liste-icon {
          background-color: $cream;
        }

        h4 {
          @include font-titre-medium;
          font-size: 1.56em;
          padding-bottom: 10px;
          padding-top: 10px;
          @include break(mobile){
            padding-top: 4px;
          }
        }
        ul {
          overflow: hidden;
          height: 0;
          line-height: 27px;
          @include break(mobile){
            margin-left: -42px;
            margin-top: 0;
          }
        }

        &.active {
          opacity: 1;
          ul {
            height: auto;
          }
        }

        &:hover {
          opacity: 1;
        }
      }

      .map_wrap {
        position: relative;
        padding-bottom: 65%;
        @include break(mobile){
          display: none;
        }

        .map {
          position: absolute;
          width: 100%;
          height: auto;
          left: 50%;
          top: 50%;
          transform: translate(-50%,-50%);
          opacity: 0;
        }
      }
    }
  }

  .section5 {
    padding-top: 80px;
    padding-bottom: 80px;

    .top {
      display: flex;
      @include break(mobile){
        flex-direction: column;
      }
      >div:first-of-type {
        width: 55%;
        @include break(mobile){
          width: 100%;
        }
      }
      >div:last-of-type {
        width: 46%;
        text-align: center;
        padding-left: 2%;
        padding-right: 2%;
        @include break(mobile){
          width: 100%;
          padding-top: 40px;
          padding-left: 0 ;
          padding-right: 0;
        }
      }
      h2 {
        font-size: 2.25em;
      }
    }

    .big_number {
      text-align: center;
      padding-top: 80px;
      padding-bottom: 45px;
      border-bottom: 1px solid $green;
      margin-bottom: 60px;

      .sup {
        text-transform: uppercase;
        @include font-titre-medium;
        font-size: 0.875em;
        letter-spacing: 2px;
        padding-left: 10%;
        padding-right: 10%;
        @include break(mobile){
          padding-left: 0%;
          padding-right: 0%;
        }
      }

      .big {
        @include font-titre-bold;
        font-size: 9em;
        @include break(mobile){
          font-size: 5em;
        }
      }

      .sub {
        font-size: 1.25em;
        padding-left: 10%;
        padding-right: 10%;
        @include break(mobile){
          padding-left: 0%;
          padding-right: 0%;
        }
      }
    }

    .col_number {
      display: flex;
      justify-content: space-around;
      @include break(mobile){
        flex-direction: column;
      }

      .number {
        width: 30%;
        text-align: center;
        @include break(mobile){
          width: 100%;
          padding-bottom: 40px;
        }

        .big {
          @include font-titre-bold;
          font-size: 4em;
          @include break(mobile){
            font-size: 3em;
          }
        }
      }
    }
  }

  .section6 {
    padding-top: 80px;
    padding-bottom: 80px;
    .container {
      display: flex;
      @include break(mobile){
        flex-direction: column;
      }
      >div {
        width: 50%;
        @include break(mobile){
          width: 100%;
        }
      }

      .text {
        padding-right: 5%;
        @include break(mobile){
          padding-right: 0%;
          padding-bottom: 60px;
        }

        h2 {
          padding-bottom: 30px;
        }
        a {
          margin-top: 60px;
          @include break(mobile){
            max-width: 290px;
            margin-left: auto;
            margin-right: auto;
            display: block;
            padding: 15px 14px;
            white-space: normal;
          }
        }
      }
    }
  }


  .section7 {
    position: relative;
    text-align: center;
    min-height: 850px;
    height: 100vh;
    @include break(mobile){
      height: 70vh;
    }

    .bg_wrapper {
      position: absolute;
      top:0;
      left: 0;
      width:100%;
      height: 100%;
      background-size: cover;
      background-position: center;

      .bg {
        position: absolute;
        top:0;
        left: 0;
        width:100%;
        height: 100%;
        z-index: 1;
      }
    }

    .content {
      z-index: 5;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);

      .round {
        border-radius: 50%;
        width: 500px;
        height: 500px;
        margin-bottom: 30px;
        padding: 45px;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        @include break(mobile){
          width: 300px;
          height: 300px;
        }

        .text_wrapper {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 85%;
          padding-top: 25px;
          padding-bottom: 25px;
          z-index: 1;
          opacity: 0;

          .titre {
            @include font-titre-medium;
            padding-bottom: 25px;
          }

          .text {
            @include font-titre-bold;
            font-size: 2.25em;
            line-height: 1.2;
            @include break(mobile){
              font-size: 1.5em;
            }
          }

        }
      }
    }

    .arc_icons {
      position: absolute;
      top: 12px;
      right: -80px;

      .icon {
        width: 86px;
        height: 86px;
        background-position: center;
        background-size: cover;
        border-radius: 50%;
        position: absolute;

        .inner_circle {
          position: absolute;
          top: -9px;
          left: -9px;

          circle {
            stroke-dasharray: 264;
            stroke-dashoffset: 0;
            transform-origin: center;
            transform: rotate(-90deg);
          }
        }
      }

      &.icon4 {
        .icon:nth-of-type(1) {
          top: 20px;
          left: 15px;
        }
        .icon:nth-of-type(2) {
          top: 136px;
          left: 68px;
        }
        .icon:nth-of-type(3) {
          bottom: 136px;
          left: 68px;
        }
        .icon:nth-of-type(4) {
          bottom: 20px;
          left: 15px;
        }
      }

      &.icon3 {
        .icon:nth-of-type(1) {
          top: 50px;
          left: 38px;
        }
        .icon:nth-of-type(2) {
          top: 196px;
          left: 72px;
        }
        .icon:nth-of-type(3) {
          bottom: 50px;
          left: 38px;
        }
      }

      &.icon2 {
        .icon:nth-of-type(1) {
          top: 100px;
          left: 56px;
        }
        .icon:nth-of-type(2) {
          bottom: 100px;
          left: 56px;
        }
      }

    }

    .btn {
      @include break(mobile){
        max-width: 300px;
        white-space: normal;
      }
    }
  }

  .section8 {
    padding-top: 80px;
    padding-bottom: 80px;

    .faq {
      padding-top: 40px;
      padding-bottom: 40px;
      display: flex;
      justify-content: space-between;
      @include break(mobile){
        flex-direction: column;
      }

      .box-faq {
        background-color: $cream;
        @include break(mobile){
          padding-bottom: 40px;
        }
      }
    }

    .bottom {
      display: block;
      text-align: center;
    }
  }

}
