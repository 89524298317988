.template-grains-archive {
  height: 100vh;
  min-height: 950px;
  padding-top: 200px;
  position: relative;
  @include break(mobile){
    padding-top: 180px;
    min-height: 800px;
  }

  h1 {
    color: $cream;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    margin-top: 0;
    @include break(mobile){
      font-size: 2rem;
    }
  }

  .img_block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 700px;
    margin-left: auto;
    margin-right: auto;
    @include break(mobile){
      width: 100%;
      margin-top: 70px;
    }

    .arrow_right {
      transform: rotate(-90deg);
      height: 70px;
      width: auto;
      cursor: pointer;
      @include break(mobile){
        height: 40px;
      }
    }
    .arrow_left {
      transform: rotate(90deg);
      height: 70px;
      width: auto;
      cursor: pointer;
      @include break(mobile){
        height: 40px;
      }
    }
  }

  .img_wrap{
    width: 350px;
    height: 350px;
    position: relative;
    @include break(mobile){
      width: 250px;
      height: 250px;
    }

    a {
      position: absolute;
      top: 0;
      left: 0;
      width: 350px;
      height: 350px;
      display: block;
      opacity: 0;
      transition: transform 0.4s 0.2s;
      pointer-events: none;
      @include break(mobile){
        width: 250px;
        height: 250px;
      }
      &:first-of-type {
        opacity: 1;
        pointer-events: all;
      }

      &:after {
        content: " ";
        width: 580px;
        height: 580px;
        background-color: $dark-green;
        position: absolute;
        top: -115px;
        left: -115px;
        border-radius: 50%;
        transform: scale(0);
        transition: transform 0.4s 0.2s;
        @include break(mobile){
          width: 430px;
          height: 430px;
          top: -100px;
          left: -100px;
        }
      }
      &:before {
        content: " ";
        color: $cream;
        text-decoration: underline;
        position: absolute;
        bottom: -45px;
        opacity: 0;
        @include font-titre-bold;
        font-size: 35px;
        width: 130%;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        z-index: 2;
        transition: opacity 0.2s;
        @include break(mobile){
          opacity: 1;
          font-size: 20px;
        }
      }

      &:hover {
        transition: transform 0.4s;
        transform: scale(0.6) translateY(-70px);
        &:after {
          transition: transform 0.4s;
          transform: scale(1) translateY(70px);
        }
        &:before {
          transition: opacity 0.5s 0.3s;
          opacity: 1;
        }
      }
    }

    .shadow_wrap {
      position: absolute;
      bottom: 0;
      left:50%;
      transform-origin: center;
      transform: translateX(-50%);
      transition: transform 0.4s $ease-in-out;
      z-index: 1;
    }

    img {
      width: 100%;
      position: relative;

      &.img {
        transition: transform 0.4s $ease-in-out;
        z-index: 2;
      }

      &.shadow {
        filter: blur(22px) grayscale(1) contrast(0) brightness(10%);
        transform: rotate(115deg) translateX(10%) translateY(-17.5%) skewX(18deg) skewY(29deg) scale(0.4);
        opacity: 0.6;
        z-index: 1;
      }
    }
  }

  .bottom {
    width: 900px;
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    height: 300px;
    margin-left: auto;
    margin-right: auto;
    z-index: 1;
    overflow: hidden;
    @include break(mobile){
      height: 220px;
    }

    .bg {
      display: block;
      background-color: #ffffff;
      width: 943px;
      height: 795px;
      border-radius: 50%;
      position: absolute;
      left: 50%;
      top: 34px;
      z-index: -1;
      transform: translate(-50%);
      @include break(mobile){
        top: 50px;
        width: 815px;
      }
    }
    svg {
      position: absolute;
      left: 45%;
      top: -1px;
      transform: rotate(-1deg);
    }
  }

  .wheel_wrapper {
    position: absolute;
    bottom: 102px;
    left: 50%;
    transform: translateX(-50%) translateY(60%);
    width: 780px;
    height: 195px;
    @include break(mobile){
      bottom: -55px;
      transform: translateX(-50%)scale(0.75);
    }

    .slider_nav {
      position: absolute;
      display: flex;
      bottom: 35px;
      left: 50%;
      align-items: center;
      transform: translateX(-50%);
      height: 100px;

      .title_wrap {
        position: relative;
        margin-left: 10px;
        margin-right: 10px;
        width: 200px;
        h3 {
          text-align: center;
          font-size: 2.25rem;
          color: $green;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 0;
          width: 100%;
          opacity: 0;
          display: none;

          a {
            text-decoration: none;
            transition: color 0.3s $ease-in-out;
            &:hover {
              color: $yellow;
            }
          }

          &:first-of-type {
            opacity: 1;
            display: block;
          }
        }
      }
    }

    .icon {
      position: absolute;
      width: 95px;
      height: 95px;
      overflow: hidden;
      top: 55px;
      left: 25px;
      transform-origin: 376% 481%;
      transform: rotate(-90deg);

      &:nth-of-type(13) {
        transform: rotate(0);
      }
      &:nth-of-type(14) {
        transform: rotate(19deg);
        img {
          transform: translate(-50%,-50%) rotate(-19deg);
        }
      }
      &:nth-of-type(1) {
        transform: rotate(38deg);
        img {
          transform: translate(-50%,-50%) rotate(-38deg);
        }
      }
      &:nth-of-type(2) {
        transform: rotate(57deg);
        img {
          transform: translate(-50%,-50%) rotate(-57deg);
        }
      }
      &:nth-of-type(3) {
        transform: rotate(76deg);
        img {
          transform: translate(-50%,-50%) rotate(-76deg);
        }
      }
      img {
        position: absolute;
        width: auto;
        height: 100%;
        top:50%;
        left: 50%;
        transform: translate(-50%,-50%);
        z-index: 1;
      }

    }
  }

}
