.template-grains {

  .content {
    display: flex;

    aside{
      width: 40%;
      text-align: center;
      color: $yellow;
      position: relative;
      height: 100vh;
      position: sticky;
      top:0;
      @include break(tablet) {
        display: none;
      }

      img {
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 50px;
      }

      .slider-step {
        position: absolute;
        top: calc(50% + 53px);
        left: 50%;
        transform: translate(-50%,-50%);
        width: 90%;
        @media only screen and (max-height: 770px) {
          top: calc(73% + 53px);
        }
        @media only screen and (max-height: 625px) {
          top: 470px;
        }
      }

      .step_inner {
        height: 550px;
        width: 100%;
        cursor: pointer;

          p {
            padding-top: 45px;
            @include font-titre-medium;
            font-size: 1.5625em;
            @media only screen and (max-height: 770px) {
              padding-top: 10px;
              font-size: 1.15em;
            }
          }

          svg {
            width: 30px;
            margin-top: 15px;
            cursor: pointer;
            @media only screen and (max-height: 770px) {
              margin-top: 0px;
            }

            g {
              transition: fill 0.4s $ease-in-out;
            }

            &:hover g{
              fill:$yellow;
            }
          }

          .inner_circle {
            width: 345px;
            height: 345px;
            margin-left: auto;
            margin-right: auto;
            position: relative;
            @include break(small-screen) {
              width: 275px;
              height: 275px;
            }
            @media only screen and (max-height: 770px) {
              width: 250px;
              height: 200px;
            }

            img {
              width: 100%;
              position: relative;

              &.img {
                transition: transform 0.4s $ease-in-out;
                z-index: 2;
              }

              &.shadow {
                filter: blur(22px) grayscale(1) contrast(0) brightness(10%);
                transform: rotate(115deg) translateX(10%) translateY(-17.5%) skewX(18deg) skewY(29deg) scale(0.4);
                opacity: 0.6;
                z-index: 1;
              }
            }

            .img_wrap{
              position: absolute;
              top:50%;
              left:50%;
              width: 90%;
              transform: translate(-50%, -50%);
              opacity: 0;
              &:first-of-type {
                opacity: 1;
              }
            }

            .shadow_wrap {
              position: absolute;
              bottom: 0;
              left:50%;
              transform-origin: center;
              transform: translateX(-50%);
              transition: transform 0.4s $ease-in-out;
              z-index: 1;
            }
          }

          &:hover {
            .img {
              transform: translateY(-6%);
            }
            .shadow_wrap {
              transform: translateX(-50%) scale(1.1);
            }
          }
        }

        .text_wrap {
          position: relative;
          width: 100%;
          height: 170px;
          @media only screen and (max-height: 770px) {
            height: 100px;
          }
          p {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            opacity: 0;

            &:first-of-type {
              opacity: 1;
            }
          }
        }
    }

    .content-left {
      width: 60%;
      padding: 90px 6.25%;
      padding-top: 200px;
      @include break(tablet) {
        width: 100%;
      }

      h1 {
        margin-top: 0;
        margin-bottom: 70px;
      }

      .block1 {
        display: flex;
        padding-bottom: 85px;
        border-bottom: 1px solid #C7BAA9;
        @include break(tablet) {
          flex-direction: column-reverse;
        }
        @include break(tablet) {
          padding-bottom: 30px;
        }
        .left {
          padding-right: 25px;
          width: 100%;

          h4 {
            margin-bottom: 20px;
          }

          .text {
            margin-bottom: 60px;
          }

          .link {
            display: inline-block;
            margin-bottom: 20px;
            @include break(tablet) {
              margin-bottom: 55px;
            }
          }
        }
        .right {
          width: 375px;
          padding-left: 25px;
          border-left: 1px solid #C7BAA9;
          font-size: 13px;
          @include break(small-screen) {
            width: 220px;
          }
          @include break(tablet) {
            width: 100%;
            border-left: none;
            padding-left: 0;
          }

          .txt-green {
            @include font-titre-medium;
            font-size: 0.875em;
            text-transform: uppercase;
          }

          .text {
            @include font-medium;
            font-size: 1.25em;
            color: $dark;
            line-height: 1.35em;
            margin-bottom: 25px;
          }

          .text-small {
            margin-bottom: 0px;
            margin-top: 45px;
            line-height: 1em;
            font-size: 0.9em;
            display: inline-block;
            font-weight: 400;
            @include break(tablet) {
              margin-top: 0px;
              margin-bottom: 55px;
            }
          }
        }
      }

      .block2 {
        padding-top: 65px;
        padding-bottom: 85px;
        border-bottom: 1px solid #C7BAA9;

        .top {
          display: flex;
          justify-content: space-between;
          padding-bottom: 60px;
          @include break(mobile) {
            flex-direction: column;
          }

          .slider_number {
            color: $green;

            .arrow {
              width: 18px;
              height: 17px;
              cursor: pointer;
            }

            .arrow-left {
                transform: rotate(-90deg);
                margin-left: 20px;
                margin-bottom: -4px;
            }
            .arrow-right {
                transform: rotate(90deg);
                margin-right: 20px;
                margin-bottom: -4px;
            }
          }
        }

        .slider {
          width: 100%;
          display: flex;
          overflow: hidden;
          flex-wrap: nowrap;
        }

        .slide {
          width: 100%;
          display: flex;
          flex-shrink: 0;

          >div {
            width: 50%;
            img {
              max-width: 100%;
              height: auto;
              display: block;
              margin-left: auto;
              margin-right: auto;
            }
            &:first-of-type {
              padding-right: 4%;
            }
            &:last-of-type {
              padding-left: 4%;
              h5{
                @include font-titre-bold;
                font-size: 2.7rem;
                margin: 0;
                line-height: 1em;
                padding-bottom: 20px;
              }
            }
          }

        }
      }

      .block3 {
        padding-top: 65px;
        padding-bottom: 85px;
        border-bottom: 1px solid #C7BAA9;

        .icon-wrap {
          display: block;
          margin-top: 35px;

          &-row {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            justify-content: flex-start;

            @include break(mobile) {
              margin-bottom: 60px;
              &:last-of-type {
                margin-bottom: 0;
              }
            }

            .icon {
              width: auto;
              display: inline-block;
              text-align: left;
              padding-right: 20px;
              @include break(mobile) {
                &:last-of-type {
                  margin-bottom: 0;
                }
              }
              img{
                display: block;
                max-width: 90px;
              }
            }
            .text {
              display: inline-block;
              h4 {
                font-size: 1.36rem;
                line-height: 1.50rem;
                margin-bottom: 10px;
              }
              div{
                font-size: 1rem;
                color: $dark;
              }
            }
          }


        }

      }

      .block4 {
        padding-top: 65px;
        text-align: center;

        .saviez-vous_small {
          width: 100%;
          position: relative;
          background-size: cover;
          background-position: center;
          padding-bottom: 66%;
          margin-bottom: 25px;
          @include break(mobile){
            padding-bottom: 175%;
          }

          .text_bg {
            width: 300px;
            height: 300px;
            background-color: $green;
            border-radius: 50%;
            color: $cream;
            padding: 40px 0;
            text-align: center;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            @include break(mobile){
              top: 40%;
            }

            .text_inner {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: 85%;
              padding-top: 25px;
              padding-bottom: 25px;
              @include break(mobile){
                width: 90%;
              }

              .titre {
                @include font-titre-medium;
                padding-bottom: 25px;
                font-size: 1rem;
              }

              b {
                @include font-titre-bold;
                font-size: 2.5em;
                padding-bottom: 25px;
                display: block;
                @include break(mobile) {
                  font-size: 2em;
                }
              }

              .text {
                font-size: 1rem;
                padding-left: 10%;
                padding-right: 10%;
              }

            }
          }
        }

        .btn {
          font-size: 1rem;
          @include break(mobile) {
            margin-top: -80%;
            background-color: $cream;
            border-color: $cream;
          }
        }
      }

    }

  }
}
