.home .loader {
  // display: none;
  background-color: $yellow;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  text-align: center;
  opacity: 0;


  .inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);

    h1 {
      font-size: 1.875em;
      color: $green;
      line-height: 90px;
    }

    .inner_circle {
      width: 345px;
      height: 345px;
      margin-left: auto;
      margin-right: auto;
      background-color: #FDECD7;
      border-radius: 50%;
      position: relative;
      @include break(small-screen) {
        width: 275px;
        height: 275px;
      }

      img {
        width: 100%;
        position: relative;

        &.img {
          transition: transform 0.4s $ease-in-out;
          z-index: 2;
          animation: bounceImg 0.8s forwards;
        }

        &.shadow {
          filter: blur(22px) grayscale(1) contrast(0) brightness(10%);
          transform: rotate(115deg) translateX(10%) translateY(-17.5%) skewX(18deg) skewY(29deg) scale(0.4);
          opacity: 0.6;
          z-index: 1;
        }
      }

      .img_wrap{
        position: absolute;
        top:50%;
        left:50%;
        width: 70%;
        transform: translate(-50%, -50%);
        opacity: 0;
        &:first-of-type {
          opacity: 1;
        }
      }

      .shadow_wrap {
        position: absolute;
        bottom: 0;
        left:50%;
        transform-origin: center;
        transform: translateX(-50%);
        transition: transform 0.4s $ease-in-out;
        z-index: 1;
        animation: bounceShadow 0.8s forwards;
      }
    }
  }
}

.template-home {
  .section1 {
    padding-top: $header;
    height: 100vh;
    position: relative;
    @include break(mobile){
      max-height: 700px;
    }

    .text_wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 40%;
      left: 50%;
      width: 95%;
      transform: translate(-50%,-50%);
      @include break(mobile){
        display: block;
        text-align: center;
      }

      .img_wrapper {
        width: 350px;
        height: 350px;
        position: relative;
        @include break(small-screen) {
          width: 200px;
          height: 200px;
        }
        @include break(mobile){
          margin-left: auto;
          margin-right: auto;
        }

        .img {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          opacity: 0;
          &:first-of-type {
            opacity: 1;
          }
        }

        svg {
          position: absolute;
          width: 100%;
          height: auto;
          top: 0;
          left: 0;
          z-index: 2;
        }
        .shadow_wrap {
          position: absolute;
          bottom: 0;
          width: 100%;
          height: 100%;
          left:50%;
          transform-origin: center;
          transform: translateX(-50%);
          z-index: 1;
        }
        .shadow {
          position: absolute;
          width: 100%;
          height: auto;
          filter: blur(22px) grayscale(1) contrast(0) brightness(10%);
          transform: rotate(117deg) translateX(20%) translateY(-13.5%) skewX(-4deg) skewY(41deg) scale(0.4);
          opacity: 0.6;
        }
      }

      .txt-big {
        @include font-titre-bold;
        font-size: 5.625em;
        line-height: 1em;
        @include break(small-screen) {
          font-size: 3.4em;
        }
      }
    }

    .wheel_wrapper {
      position: absolute;
      bottom: -7px;
      left: 50%;
      transform: translateX(-50%);
      @include break(mobile){
        transform: translateX(-50%)scale(0.65);
        bottom: -36px;
      }

      .slider_nav {
        position: absolute;
        display: flex;
        bottom: 0;
        left: 50%;
        align-items: center;
        transform: translateX(-50%);
        height: 100px;

        .title_wrap {
          position: relative;
          margin-left: 10px;
          margin-right: 10px;
          width: 200px;
          h3 {
            text-align: center;
            font-size: 2.25rem;
            color: $green;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            width: 100%;
            opacity: 0;
            display: none;

            a {
              text-decoration: none;
              transition: color 0.3s $ease-in-out;
              &:hover {
                color: $yellow;
              }
            }

            &:first-of-type {
              opacity: 1;
              display: block;
            }
          }
        }
      }

      .arrow {
        width: 27px;
        height: 40px;
        align-items: center;
        cursor: pointer;
        margin-bottom: -10px;

        &:first-of-type {
          transform: rotate(90deg);
        }
        &:last-of-type {
          transform: rotate(-90deg);
        }
      }

      .icon {
        position: absolute;
        width: 88px;
        height: 88px;
        background-color: $cream;
        border: 1px solid $green;
        border-radius: 50%;
        overflow: hidden;
        top: 55px;
        left: 25px;
        transform-origin: 376% 481%;
        cursor: pointer;
        transform: rotate(-90deg);

        &:nth-of-type(13) {
          transform: rotate(0);
        }
        &:nth-of-type(14) {
          transform: rotate(19deg);
          img {
            transform: translate(-50%,-50%) rotate(-19deg);
          }
        }
        &:nth-of-type(1) {
          transform: rotate(38deg);
          background-color: $yellow;
          border-color: $yellow;
          img {
            transform: translate(-50%,-50%) rotate(-38deg);
          }
        }
        &:nth-of-type(2) {
          transform: rotate(57deg);
          img {
            transform: translate(-50%,-50%) rotate(-57deg);
          }
        }
        &:nth-of-type(3) {
          transform: rotate(76deg);
          img {
            transform: translate(-50%,-50%) rotate(-76deg);
          }
        }
        img {
          position: absolute;
          width: auto;
          height: 70px;
          top:50%;
          left: 50%;
          transform: translate(-50%,-50%);
          z-index: 1;
        }
        svg{
          position: absolute;
          top:50%;
          left: 50%;
          transform: translate(-50%,-50%);
          z-index: 2;
        }
      }
    }
  }

  .section2 {
    padding: 150px 14%;
    text-align: center;
    font-size: 2.25em;
    position: relative;
    overflow: hidden;
    @include break(mobile){
      padding: 60px 5%;
      font-size: 1.625em;
    }

    h2 {
      font-size: 1.666em;
      padding-bottom: 55px;
      @include break(mobile){
        font-size: 1.38em;
      }
    }

    .text {
      @include font-titre-bold;
      font-size: 1em;
      line-height: 1.2em;
      padding-bottom: 75px;
      position: relative;
      z-index: 2;

      strong {
        color: rgba(0, 98, 87, 0);
        -webkit-text-stroke: 1px $cream;
        cursor: pointer;
        // text-shadow: 2px 3px 6px rgba(35, 31, 32, 0);
        // transition: text-shadow 0.6s;
        white-space: nowrap;

        // &:hover {
        //   text-shadow: 2px 3px 6px rgba(35, 31, 32, 0.8);
        // }
      }
    }

    .btn {
      font-size: 20px;
      @include break(mobile){
        font-size: 16px;
      }
    }

    video {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      height: 105%;
      width: 191vh;
      min-width: 100%;
      min-height: 56.25vw;
      z-index: 1;
      opacity: 0;
      display: none;
    }
  }

  .section3 {
    position: relative;
    text-align: center;
    min-height: 850px;
    height: 100vh;
    @include break(mobile){
      height: 70vh;
    }

    .bg_wrapper {
      position: absolute;
      top:0;
      left: 0;
      width:100%;
      overflow: hidden;
      height: 100%;

      .bg {
        position: absolute;
        top:0;
        left: 0;
        width:100%;
        height: 100%;
        z-index: 1;
        background-size: cover;
        background-position: center;
        opacity: 0;

        &:first-of-type {
          opacity: 1;
        }
      }
    }

    .content {
      z-index: 5;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);

      .round {
        border-radius: 50%;
        width: 500px;
        height: 500px;
        margin-bottom: 30px;
        padding: 45px;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        @include break(mobile){
          width: 300px;
          height: 300px;
        }

        .text_wrapper {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 85%;
          padding-top: 25px;
          padding-bottom: 25px;
          z-index: 1;
          opacity: 0;
          @include break(mobile){
            width: 75%;
          }

          .titre {
            @include font-titre-medium;
            padding-bottom: 25px;
          }

          .text {
            @include font-titre-bold;
            font-size: 2.25em;
            line-height: 1.2;
            @include break(mobile){
              font-size: 1.3em;
            }
          }

        }
      }
      .inner_circle_big {
        position: absolute;
        top: 0;
        left: 0;
        transform: rotate(-90deg);
      }
    }

    .arc_icons {
      position: absolute;
      top: 12px;
      right: -80px;
      @include break(small-screen) {
        right: -55px;
      }
      @include break(mobile){
        display: none;
      }

      .icon {
        width: 86px;
        height: 86px;
        background-position: center;
        background-size: cover;
        border-radius: 50%;
        position: absolute;
        cursor: pointer;

        .inner_circle {
          position: absolute;
          top: -9px;
          left: -9px;

          circle {
            transform-origin: center;
            transform: rotate(-90deg);
          }
        }

        &:first-of-type {
          pointer-events: none;
        }
      }

      &.icon4 {
        .icon:nth-of-type(1) {
          top: 20px;
          left: 15px;
        }
        .icon:nth-of-type(2) {
          top: 136px;
          left: 68px;
        }
        .icon:nth-of-type(3) {
          bottom: 136px;
          left: 68px;
        }
        .icon:nth-of-type(4) {
          bottom: 20px;
          left: 15px;
        }
      }

      &.icon3 {
        .icon:nth-of-type(1) {
          top: 50px;
          left: 38px;
        }
        .icon:nth-of-type(2) {
          top: 196px;
          left: 72px;
        }
        .icon:nth-of-type(3) {
          bottom: 50px;
          left: 38px;
        }
      }

      &.icon2 {
        .icon:nth-of-type(1) {
          top: 100px;
          left: 56px;
        }
        .icon:nth-of-type(2) {
          bottom: 100px;
          left: 56px;
        }
      }

    }

    .btn {
      @include break(mobile){
        max-width: 300px;
        white-space: normal;
      }
    }

    .btn_wrapper {
      justify-content: space-between;
      width: 60%;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 80px;
      display: none;
      @include break(mobile) {
        display: flex;
      }

      .arrow {
        background-color: $cream;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        position: relative;
          svg {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 15px;
            height: auto;
          }
        &.arrow_left svg{
          transform: translate(-50%, -50%) rotate(90deg);
        }
        &.arrow_right svg{
          transform: translate(-50%, -50%) rotate(-90deg);
        }
      }
    }
  }

  .section4 {
    padding: 150px 10%;
    display: flex;

    @include break(mobile){
      padding: 60px 5%;
      flex-direction: column;
    }

    .image {
      width: 320px;
      @include break(mobile){
        width: 112px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 25px;
      }

      img {
        width: 100%;
        height: auto;
      }
    }

    .text {
      width: 72%;
      padding-left: 8%;
      white-space: normal;
      @include break(mobile){
        width: 100%;
        padding-left: 0;
      }

      h2 {
        padding-bottom: 35px;

        @include break(mobile){
          font-size: 1.875em;
        }
      }

      font-size: 1.125em;
      line-height: 1.4em;

      .btn {
        margin-top: 80px;
      }
    }
  }

  .section5 {
    background-image: url(../img/home_bg.jpg);
    background-size: cover;
    padding: 150px 5% 400px 5%;
    text-align: center;
    background-position: center;

    h2 {
      font-size: 3.75em;
      padding-bottom: 60px;
      @include break(mobile){
        font-size: 2.25em;
      }
    }
  }

}
