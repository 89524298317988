

@keyframes loaderAnim {
	0% {
		background-position: 0% 100%;
	}
	100% {
		background-position: 200% 100%;
	}
}

.home .not_visible {
  opacity: 0;
}

.block_col1-img, .block_col1-text, .block_col1-btn, .block_col2-block_top, .block_col2-block_back, .gradient_bar {
  opacity: 0;
}

.result_block .block_col1-img, .result_block .block_col1-text, .result_block .block_col1-btn, .result_block .block_col2-block_top, .result_block .block_col2-block_back, .result_block .gradient_bar {
  opacity: 1;
}
