.quatre-cent-quatre {
  min-height:100vh;
  padding-top: 150px;
  text-align: left;
  h1 {
    font-size:10em;
    margin: 0.3em 0;
  }
  h2 {
    font-size:2.5em;
  }
}
