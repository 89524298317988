header {
  position: fixed;
  width: 100%;
  top:0;
  left: 0;
  z-index: 9999;
  background-color: rgba(255,255,255,1);
  transition: background-color 0.4s;

  nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 15px;
    vertical-align: middle;
    padding-left: 5%;
    padding-right: 5%;
    position: relative;
    @include font-titre-bold;
    font-size: 1rem;
    transition: padding 0.6s;
    @include break(down-tablet) {
      justify-content: center;
      background-color: rgba(255,255,255,1);
      &:after {
        content: ' ';
        height: 1px;
        width: 0%;
        position: absolute;
        bottom:0;
        left: 0;
        background-color: #979797;
        transition: width 0.3s $ease-in-out;
      }
    }

    .div_logo {

      @include break(down-tablet) {
        width: auto;
      }
      a {
        display: block;
      }
    }

    .custom-logo-link img{
      width: 76px;
      height: 76px;
      height: auto;
      transition: all 0.4s;
      @include break(down-tablet) {
        width: 52px;
        height: 52px;
      }
    }

    a {
      text-decoration: none;
    }

    ul li {
      display: inline-block;

      &.current-menu-item {
        color: $yellow;
      }
    }

    .left {

      li {
        padding-right: 35px;
      }

      @include break(down-tablet) {
        display: none;
      }

    }

    .right {
      .btn {
        font-size: 1rem;
        padding: 10px 20px;
        margin-left: 20px;
      }
      @include break(down-tablet) {
        display: none;
      }
    }

    .btn_mobile {
      width: 21px;
      height: 21px;
      position: absolute;
      top: 50%;

      transform: translateY(-50%);
      cursor: pointer;

      @include break(down-tablet) {
        right: 40px;
      }

      @include break(mobile) {
        right: 20px;
      }

      .dot {
        position: absolute;
        line-height: 4px;
        // transform: translate(-50%, -50%);

        &:nth-of-type(1){
          top: 0;
          left: 0;
        }
        &:nth-of-type(2){
          top: 0;
          left: calc(50% - 2px);
        }
        &:nth-of-type(3){
          top: 0;
          right: 0;
        }
        &:nth-of-type(4){
          top: calc(50% - 2px);
          left: 0;
        }
        &:nth-of-type(5){
          top: calc(50% - 2px);
          left: calc(50% - 2px);
        }
        &:nth-of-type(6){
          top: calc(50% - 2px);
          right: 0;
        }
        &:nth-of-type(7){
          bottom: 0;
          left: 0;
        }
        &:nth-of-type(8){
          bottom: 0;
          left: calc(50% - 2px);
        }
        &:nth-of-type(9){
          bottom: 0;
          right: 0;
        }
      }
    }
  }

  .nav_mobile {
    text-align: center;
    background-color: #ffffff;
    height: 100vh;
    width: 100vw;
    padding-top: 82px;
    padding-left: 30px;
    padding-right: 30px;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    display: none;
    z-index: -1;

    a {
      color: $green;
      text-decoration: none;
      @include font-titre-bold;
      font-size: 1.25em;
    }
    li {
      border-bottom: 1px solid rgba(0,0,0,0.2);
      padding: 30px;
    }
    .btn {
      margin-top: 55px;
      @include break(down-tablet) {
        font-size: 0.9em;
        padding: 15px 25px;
      }
    }

    .current-menu-item a{
      color: $yellow;
    }
  }

  &.open {
    nav:after {
      width: 100%;
    }
  }

  &.bg_white {
    background-color: rgba(255,255,255,1);

    nav {
      padding-top: 0px;
      padding-bottom: 0px;
      @include break(down-tablet) {
        padding-top: 15px;
        padding-bottom: 15px;
      }
    }

    .logo {
      width: 72px;
      @include break(down-tablet) {
        width: 52px;
          @include font-titre-bold;
      }
    }
  }

  .second_nav {
    display: none;

    .trigger {
      background-color: $dark_green;
      width: 100%;
      height: 50px;
      color: $cream;
      padding-left: 5%;
      padding-right: 5%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      @include font-titre-medium;
      cursor:pointer;

      svg {
        width: 18px;
        margin-left: 20px;
      }
    }
    .drop {
      display: flex;
      flex-wrap: wrap;
      background-color: #ffffff;
      overflow: hidden;
      height: 0;
      opacity: 0;
      justify-content: space-between;
      @include break(down-tablet) {
        overflow-x: hidden;
        overflow-y: scroll;
      }

      a {
        padding-left: 20px;
        padding-right: 20px;
        width: 16%;
        height: 92px;
        display: flex;
        align-items: center;
        text-decoration: none;
        color: $green;
        @include font-bold;
        position: relative;
        overflow: hidden;
        z-index: 1;
        @include break(small-screen) {
          width: 20%;
        }
        @include break(tablet) {
          width: 25%;
        }
        @include break(down-tablet) {
          width: 50%;
          padding-left: 5px;
          padding-right: 5px;
          font-size: 0.9em;
        }

        &:after{
          content: " ";
          width: 100%;
          height: 0%;
          background-color: $yellow;
          position: absolute;
          bottom: 0;
          left: 0;
          transform: translateX(-98.5%);
          transition: height 0.2s $ease-out, transform 0.4s 0.3s $ease-out;
          z-index: -1;
        }

        &:hover:after {
          @include break(desktop-and-tablet) {
            height: 100%;
            transform: translateX(0)translateY(0);
          }
        }
        &.current:after {
          height: 100%;
          transform: translateX(0)translateY(0);
        }
      }
      img {
        height: 45px;
        width: auto;
        display: inline-block;
        margin-right: 10px;
      }

      i.filler {
        width: 16%;
        height: 1px;
        @include break(small-screen) {
          width: 20%;
        }
        @include break(tablet) {
          width: 25%;
        }
        @include break(down-tablet) {
          width: 50%;
        }
      }
    }
  }
}

.home header {
  background-color: rgba(255,255,255,0);
  &.bg_white {
    background-color: rgba(255,255,255,1);
  }
}

.single-grains, .post-type-archive-grains {
  .second_nav{
    display: block;
  }
}

.post-type-archive-grains .second_nav{
  border-bottom: 1px solid #ffffff;
}


// Sub Menu
.no_link, .menu-item-has-children {
  position: relative;
  cursor: pointer;
  >a{
    pointer-events: none;
  }

}


.menu-item-has-children {
  >a {
    &:after {
      content: url('/wp-content/themes/oxo_theme/dist/img/arrow.svg');
      display:inline-block;
      padding-left: 10px;
      transition: 300ms;
    }
  }
  @include break(up-tablet) {
    &:hover {
      >a {
        color: $yellow;
        &:after {
          transform: translateY(4px) rotateX(180deg);
        }
      }
    }
  }

  &.open >a:after {
    transform: translateY(4px) rotateX(180deg);
  }


}

ul.sub-menu {
  position: absolute;
  top: 45px;
  padding: 0px 30px;
  left: -10px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  display: none;
  overflow: hidden;
  border-radius: 10px;
  @include break(down-tablet) {
    position: relative;
    top: auto;
    padding: 0;
    left: 0px;
    border-radius: 0;
  }
  li {
    padding-bottom: 20px;
    border-bottom:none;
    @include break(down-tablet) {
      padding-bottom: 10px;
      padding-top: 10px;
    }
    &:last-child {
      padding-bottom: 30px;
      @include break(down-tablet) {
        padding-bottom: 0;
      }
    }
    &:first-child {
      padding-top: 30px;
    }
    a {
      @include break(down-tablet) {
       font-size: 1em;
      }
      &:hover {
        color: $yellow;
      }
    }
  }
}


.menu-navigation-droite-container {
  ul {
    li {
      &:hover {
        a {
          color: $yellow;
        }
      }
      &.btn {
        &:hover {
          a {
            color: white;
          }
        }
      }
    }
  }
}

.post-type-archive-grains header .nav_mobile, .single-grains header .nav_mobile {
  padding-top: 132px;
}
