

.template-landing {

  .loader {
    // display: none;
    background-color: $yellow;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 9999;
    text-align: center;

    .inner {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);

      h1 {
        font-size: 1.875em;
        color: $green;
        line-height: 90px;
      }

      .inner_circle {
        width: 345px;
        height: 345px;
        margin-left: auto;
        margin-right: auto;
        background-color: #FDECD7;
        border-radius: 50%;
        position: relative;
        @include break(small-screen) {
          width: 275px;
          height: 275px;
        }

        img {
          width: 100%;
          position: relative;

          &.img {
            transition: transform 0.4s $ease-in-out;
            z-index: 2;
            animation: bounceImg 0.8s forwards;
          }

          &.shadow {
            filter: blur(22px) grayscale(1) contrast(0) brightness(10%);
            transform: rotate(115deg) translateX(10%) translateY(-17.5%) skewX(18deg) skewY(29deg) scale(0.4);
            opacity: 0.6;
            z-index: 1;
          }
        }

        .img_wrap{
          position: absolute;
          top:50%;
          left:50%;
          width: 70%;
          transform: translate(-50%, -50%);
          opacity: 0;
          &:first-of-type {
            opacity: 1;
          }
        }

        .shadow_wrap {
          position: absolute;
          bottom: 0;
          left:50%;
          transform-origin: center;
          transform: translateX(-50%);
          transition: transform 0.4s $ease-in-out;
          z-index: 1;
          animation: bounceShadow 0.8s forwards;
        }
      }
    }
  }


  .content {
    display: flex;
    @include break(mobile) {
      display: block;
    }

    aside{
      width: 40%;
      text-align: center;
      color: $yellow;
      position: relative;
      height: 100vh;
      position: sticky;
      top:0;
      @include break(tablet) {
        display: none;
      }

      img {
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 50px;
      }

      .slider-step {
        position: absolute;
        top: calc(50% + 53px);
        left: 50%;
        transform: translate(-50%,-50%);
        width: 90%;
      }

      .step_inner {
        height: 550px;
        width: 100%;
        cursor: pointer;

          p {
            padding-top: 45px;
            @include font-titre-medium;
            font-size: 1.5625em;
          }

          svg {
            width: 30px;
            margin-top: 5px;
            cursor: pointer;

            g {
              transition: fill 0.4s $ease-in-out;
            }

            &:hover g{
              fill:$yellow;
            }
          }

          .inner_circle {
            width: 345px;
            height: 345px;
            margin-left: auto;
            margin-right: auto;
            border: solid $dark_green 2px;
            border-radius: 50%;
            position: relative;
            @include break(small-screen) {
              width: 275px;
              height: 275px;
            }

            img {
              width: 100%;
              position: relative;

              &.img {
                transition: transform 0.4s $ease-in-out;
                z-index: 2;
              }

              &.shadow {
                filter: blur(22px) grayscale(1) contrast(0) brightness(10%);
                transform: rotate(115deg) translateX(10%) translateY(-17.5%) skewX(18deg) skewY(29deg) scale(0.4);
                opacity: 0.6;
                z-index: 1;
              }
            }

            .img_wrap{
              position: absolute;
              top:50%;
              left:50%;
              width: 90%;
              transform: translate(-50%, -50%);
              opacity: 0;
              &:first-of-type {
                opacity: 1;
              }
            }

            .shadow_wrap {
              position: absolute;
              bottom: 0;
              left:50%;
              transform-origin: center;
              transform: translateX(-50%);
              transition: transform 0.4s $ease-in-out;
              z-index: 1;
            }
          }

          &:hover {
            .img {
              transform: translateY(-6%);
            }
            .shadow_wrap {
              transform: translateX(-50%) scale(1.1);
            }
          }
        }

        .text_wrap {
          position: relative;
          width: 100%;
          height: 170px;
          p {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            opacity: 0;

            &:first-of-type {
              opacity: 1;
            }
          }
        }
    }

    .content-left {
      width: 60%;
      padding: 225px 6.25% 90px 6.25%;
      @include break(tablet) {
        padding: 120px 5%;
        width: 100%;
      }

      .video_player {
        width: 100vw;
        height: 100vh;
        background-color: rgba(0,0,0,0.8);
        position: fixed;
        z-index: 999;
        top: 0;
        left: 0;
        opacity: 0;
        display: none;

        video {
          width: 90%;
          height: auto;
          position: absolute;
          left: 50%;
          top:50%;
          transform: translate(-50%, -50%);
        }

        .close {
          background-color: $yellow;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          text-align: center;
          color: #ffffff;
          font-size: 25px;
          padding-top: 8px;
          position: absolute;
          right: 10px;
          top: 10px;
          @include font-titre-bold;
          cursor: pointer;
        }
      }

      .placeholder {
        width: 100%;
        max-width: 425px;
        height: 238px;
        border-radius: 10px;
        background-size: cover;
        background-position: center;
        position: relative;
        @include break(mobile) {
          height: auto;
          padding-bottom: 56%;
        }

        .play_video {
          background-color: $yellow;
          border-radius: 50%;
          width: 70px;
          height: 70px;
          position: absolute;
          left: 50%;
          top:50%;
          transform: translate(-50%, -50%);
          cursor: pointer;

          svg {
            position: absolute;
            left: 55%;
            top:50%;
            transform: translate(-50%, -50%);
            width: 20px;
          }
        }
      }

      h1 {
        margin-top: 85px;
        margin-bottom: 85px;
        @include break(mobile) {
          margin-top: 55px;
          margin-bottom: 55px;
        }
      }

      h3 {
        @include font-titre-bold;
        color: $green;
        margin-bottom: 40px;
        font-size: 1.5625em;
        @include break(mobile) {
          margin-bottom: 30px;
        }
      }

      .text {
        p, span {
          line-height: 1.5em;
        }
        margin-bottom: 115px;
        @include break(mobile) {
          margin-bottom: 60px;
        }
      }

    }

  }

  .sec_bottom {
    padding-top: 150px;
    padding-bottom: 150px;
    background-color: #ffffff;
    position: relative;
    z-index: 90;
    @include break(mobile) {
      padding-top: 80px;
      padding-bottom: 80px;
    }

    .container {
      display: flex;
      justify-content: space-between;
      text-align: center;
      @include break(small-screen) {
        width: 75%;
      }

      @include break(mobile) {
        width: 90%;
        display: block;
      }

      a{
        color: $green;
        text-decoration: none;
        white-space: normal;
        &:hover .round_hover{
          transform: rotate(0deg);
          transition: transform 0.4s $ease-out;
          svg {
            opacity: 1;
            transform: translate(-50%, -50%) rotate(-90deg);
            transition: opacity 0.4s 0.3s $ease-out, transform 0.4s 0.3s $ease-out;
          }
        }
      }

      h4 {
        @include font-titre-bold;
        font-size: 2em;
        line-height: 1.1em;
        margin-top: 25px;
        @include break(mobile) {
          font-size: 1.5em;
        }
      }

      .round {
        width: 375px;
        height: 375px;
        border-radius: 50%;
        position: relative;
        background-color: $dark_green;
        position: relative;
        overflow: hidden;
        @include break(small-screen) {
          width: 275px;
          height: 275px;
        }

        @include break(mobile) {
          width: 175px;
          height: 175px;
          margin-left: auto;
          margin-right: auto;
        }

        svg {
          width: 106px;
          position: absolute;
          left: 50%;
          top:50%;
          transform: translate(-60%, -50%) rotate(-90deg);
          @include break(mobile) {
            width: 60px;
          }
        }

        img {
          width: auto;
          height: 205px;
          position: absolute;
          left: 50%;
          top:50%;
          transform: translate(-50%, -50%);
          @include break(small-screen) {
            height: 150px;
          }
          @include break(mobile) {
            height: 105px;
          }
        }

        .round_hover {
          background-color: $yellow;
          position: absolute;
          width: 375px;
          height: 375px;
          border-radius: 50%;
          transform: rotate(-175deg);
          transform-origin: left bottom;
          transition: transform 0.4s 0.3s $ease-in;
          @include break(small-screen) {
            width: 275px;
            height: 275px;
          }
          @include break(mobile) {
            width: 175px;
            height: 175px;
          }

          svg {
            opacity: 0;
            transition: opacity 0.4s $ease-in, transform 0.4s 0.3s $ease-in;
          }
        }

      }

      >div{
        width: 375px;
        @include break(small-screen) {
          width: 275px;
        }
        @include break(mobile) {
          width: 90%;
          display: block;
          margin-left: auto;
          margin-right: auto;
        }

        &:first-of-type {
          @include break(mobile) {
            margin-bottom: 80px;
          }
        }
      }
    }
  }
}




@keyframes bounceImg {
from,
60%,
75%,
90%,
to {
  animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

0% {
  opacity: 0;
  transform: translate3d(0, -3000px, 0);
}

60% {
  opacity: 1;
  transform: translate3d(0, 25px, 0);
}

75% {
  transform: translate3d(0, 0px, 0);
}

90% {
  transform: translate3d(0, 5px, 0);
}

to {
  transform: translate3d(0, 0, 0);
}
}

@keyframes bounceShadow {
from,
60%,
75%,
90%,
to {
  animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

0% {
  opacity: 0;
  transform: translateX(-50%) scale(1.4);
}

60% {
  opacity: 0.5;
  transform: translateX(-50%) scale(1.2);
}

75% {
  opacity: 1;
  transform: translateX(-50%) scale(1);
}

90% {
  transform: translateX(-50%) scale(1.1);
}

to {
  transform: translateX(-50%) scale(1);
}
}
