.template-faq-archive {

  .banner {
    width: 100%;
    height: 700px;
    background-size: cover;
    background-position: center bottom;
    background-repeat: no-repeat;
    position: relative;

    .container {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
    }
  }

  .content {
    position: relative;
    margin-top: -80px;

    h2 {
      margin-bottom: 50px;
      @include break(mobile){
        width: 100%;
        text-align: center;
      }
    }
  }

  .row-container {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      flex-basis: 33%;
      flex-flow: wrap;
      margin-bottom: 75px;
      @include break(tablet){
        justify-content: space-around;
      }
  }

  .section5 {
    background-image: url(../img/home_bg.jpg);
    background-size: cover;
    padding: 150px 5% 250px 5%;
    text-align: center;
    background-position: center;

    h2 {
      font-size: 3.75em;
      padding-bottom: 60px;
      padding-top: 60px;
      @include break(mobile){
        font-size: 2.25em;
      }
    }
  }
}

.box-faq {
  width: 305px;
  height: 315px;
  background-color: $white;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  margin-bottom: 35px;
  @include break(mobile){
    margin-left: auto;
    margin-right: auto;
  }

  a {
    text-decoration: none;
    display: block;
  }

  .image {
    width: 100%;
    height: 150px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    transition: height 0.4s $ease-in-out-quad;
  }

  .category {
    padding: 20px 25px 10px 25px;
    text-transform: uppercase;
    @include font-medium;
    font-size: 14px;
    white-space: normal;
  }

  .text {
    font-size: 18px;
    @include font-titre-bold;
    padding: 0px 70px 20px 25px;
    white-space: normal;
    line-height: 1.3em;
  }

  .prev {
    width: 100%;
    white-space: normal;
    padding-left: 25px;
    padding-right: 55px;
    font-size: 14px;
    color: $green;
    opacity: 0;
    transition: opacity 0.4s $ease-in-out-quad;

    strong {
      display: block;
      margin-top: 10px;
    }
  }

  .corner {
    position: absolute;
    background-color: $yellow;
    width: 274px;
    height: 274px;
    border-radius: 50%;
    left: 225px;
    top: 225px;

    .arrow {
      width: 26px;
      height: 28px;
      transform: rotate(-90deg);
      position: absolute;
      top: 48px;
      left: 40px;
    }
  }

  &:hover {
    .image {
      height: 0px;
    }
    .prev {
      opacity: 1;
    }
  }
}

i.filler {
  width: 305px;
  height: 1px;
}


.filter--box{
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;

  @include break(mobile){
    display: block;
    margin-bottom: 20px;
  }

  .cats-list{
    margin-bottom: 15px;
    display: flex;
    flex-wrap: wrap;
    list-style: none;

    @include break(mobile){
      display: block;
      margin-bottom: 20px;
    }

    .cat-item{
      margin-right: 20px;
      transition: .3s;
      text-decoration: none;
      padding: 14px;
      color: $green;
      background: $white;
      border-radius: 6px;
      display: inline-block;
      font-weight: 600;
      cursor: pointer;

      &:hover,
      &.active{
        background: $green;
        color: $white;
      }
    }
  }

  .orderby{
    position: relative;
    padding-bottom: 12px;
    border-bottom: 2px solid $green;
    margin-left: 50px;

    @include break(mobile){
      margin-left: 0;
      display: inline-block;
    }

    .svg{
      transition: .3s;
      position: absolute;
      right: 0;
      top: 5px;
      transform: rotateX(0);
      transform-origin: center;
    }

    &.open{
      .svg{
        transform: rotateX(180deg);

      }
    }

    .order-header{
      @include font-titre-bold;
      color: $green;
      font-size: 16px;
      cursor: pointer;
      display: flex;
      align-items: center;
      padding-right: 40px;
    }

    .wrapper-list{
      height: 0;
      display: none;
      overflow: hidden;
      position: absolute;
      top: 100%;
      right: 0;
      z-index: 2;
      white-space: nowrap;
      padding-top: 20px;

      @include break(mobile){
        right: auto;
        left: 0;
      }
    }

    ul.filter-order{
      border: 1px solid lightgrey;
      background: $white;
      border-radius: 6px;
      padding: 10px;

      @include break(mobile){
         padding: 5px;
      }

      li:last-of-type a{
        margin-bottom: 0;
      }

      li{
        padding: 12px 20px;
        transition: .3s;
        color: $green;
        text-decoration: none;
        cursor: pointer;

        @include break(mobile){
          padding: 10px 8px;
        }

        &:hover,
        &.active{
          color: $yellow;
        }
      }
    }
  }
}

.faqlist{
  .box-faq{
    transition: .5s;
  }
}













