.template-bienfaits {

  .banner {
    width: 100%;
    height: 700px;
    background-size: cover;
    background-position: center bottom;
    background-repeat: no-repeat;
    position: relative;

    .container {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
    }
  }


  .section1 {
    padding-top: 80px;
    padding-bottom: 120px;
    .container {
      display: flex;
      @include break(mobile){
        flex-direction: column;
      }
      >div {
        width: 50%;
        @include break(mobile){
          width: 100%;
        }
      }

      .text {
        padding-right: 5%;
        @include break(mobile){
          padding-right: 0%;
          padding-bottom: 60px;
        }

        h2 {
          padding-bottom: 30px;
        }
        .btn {
          margin-top: 60px;
          @include break(mobile){
            max-width: 290px;
            margin-left: auto;
            margin-right: auto;
            display: block;
            padding: 15px 14px;
            white-space: normal;
          }
        }
      }
      img {
        max-height: 400px;
        margin: auto;
      }
    }
  }


  .section2 {
    padding-top: 65px;
    padding-bottom: 85px;
    border-bottom: 1px solid #C7BAA9;

    .text {
      text-align: center;
      margin-left: auto;
      h2 {
        margin-bottom: 80px;
      }
    }

    .icon-wrap {
      display: flex;
      justify-content: space-around;
      margin-top: 35px;
      margin-bottom: 80px;
      @include break(mobile) {
        flex-direction: column;
        align-items: center;
      }

      h4 {
        margin-bottom: 20px;
      }

      .icon {
        width: 20%;
        text-align: center;
        @include break(mobile) {
          margin-bottom: 30px;
          width: 100%;
          &:last-of-type {
            margin-bottom: 0;
          }
        }
        img{
          display: block;
          max-width: 90px;
          height: auto;
          margin-left: auto;
          margin-right: auto;
          margin-bottom: 15px;
        }
        div{
          font-size: 1rem;
        }
      }
    }

    .button {
      text-align: center;
      margin: auto;
    }
  }


  .section3 {
    padding-top: 80px;
    padding-bottom: 80px;
    @include break(mobile) {
      padding-bottom: 0px;
    }
    .container {
      display: flex;
      @include break(mobile){
        flex-direction: column;
      }
      >div {
        width: 50%;
        @include break(mobile){
          width: 100%;
        }
      }

      .text {
        padding-left: 5%;
        @include break(mobile){
          padding-left: 0%;
          padding-bottom: 60px;
          padding-top: 30px;
        }

        h2 {
          padding-bottom: 30px;
          padding-left: 20px;
          @include break(mobile){
            padding-left: 0;
          }
        }
        .btn {
          margin-top: 60px;
          @include break(mobile){
            max-width: 290px;
            margin-left: auto;
            margin-right: auto;
            display: block;
            padding: 15px 14px;
            white-space: normal;
          }
        }
      }
    }
  }


  .section4 {
    padding-top: 60px;
    padding-bottom: 120px;

    @include break(mobile){
      padding-top: 20px;
    }

    .container {
      display: flex;
      align-items: center;
      @include break(mobile){
        flex-direction: column;
      }
      >div:first-of-type {
        width: 60%;
        padding-right: 5%;
        @include break(mobile){
          width: 100%;
          padding-right: 0;
        }
        h2 {
          color: $green;
          padding-bottom: 40px;
        }
      }
      .contenu {
        margin-bottom: 30px;
      }
    }

    .section4_saviezvous {

      @include break(mobile){
        padding-top: 60px;
      }

      .wrapper {
        background-color: $green;
        color: $cream;
        border-radius: 50%;
        width: 300px;
        height: 300px;
        overflow: hidden;
        position: relative;
        margin-left: auto;
        margin-right: auto;

        div {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          text-align: center;
          opacity: 0;
          width: 90%;

          &:first-of-type {
            opacity: 1;
          }

          .titre {
            text-transform: uppercase;
            @include font-titre-medium;
            font-size: 0.875em;
            letter-spacing: 2px;
            padding-left: 10%;
            padding-right: 10%;
            margin-bottom: 20px;
          }

          .text {
            b {
              @include font-titre-bold;
              font-size: 2.7em;
              padding-top: 10px;
              padding-bottom: 10px;
              display: block;
              margin-bottom: 20px;
            }
            font-size: 1em;
            padding-left: 10%;
            padding-right: 10%;
          }
        }
      }

    }

  }


  .section5 {
    padding-top: 80px;
    padding-bottom: 80px;

    .faq {
      padding-top: 40px;
      padding-bottom: 40px;
      display: flex;
      justify-content: space-between;
      @include break(mobile){
        flex-direction: column;
      }

      .box-faq {
        background-color: $cream;
        @include break(mobile){
          padding-bottom: 40px;
        }
      }
    }

    .bottom {
      display: block;
      text-align: center;
    }
  }

}
