footer {
  padding-top: 60px;
  padding-bottom: 20px;

  .container {
    display: flex;
    justify-content: space-between;
    @include break(tablet){
      flex-direction: column;
      text-align: center;
    }
  }

  .logo {
    width: 160px;
    height: auto;
    @include break(tablet){
      margin-left: auto;
      margin-right: auto;
      width: 80px;
    }
  }

  .bold {
    font-size: 1rem;
    padding-bottom: 15px;
    @include font-bold;
  }

  a {
    text-decoration: none;
    @include break(mobile) {
      text-decoration: none;
      padding-top: 17px;
      padding-bottom: 17px;
      display: inline-block;
    }
  }

  ul.menu {
    list-style: none;
    font-size: 0.875em;
    line-height: 1.6em;
    padding-left: 0px;
    padding-top: 20px;
  }

  li.social {
    padding-top: 15px;

    a{
      margin-right: 10px;
      padding-top: 14px;
      @include break(mobile) {
        margin-right: 20px;
        margin-left: 20px;
        padding-top: 24px;
        padding-left: 10px;
        padding-right: 10px;
      }
    }

    svg path{
      fill:$cream;
    }
  }
}

.post-type-archive-grains footer{
  background-color: $dark_green;
}

.copyright {
    text-align: center;
    font-size: 10px;
    padding-top: 40px;
}
