// Work Sans Thin
@mixin font-thin {
  font-family: work-sans, sans-serif;
  font-weight: 100;
  font-style: normal;
}

// Work Sans ExtraLight
@mixin font-xlight {
  font-family: work-sans, sans-serif;
  font-weight: 200;
  font-style: normal;
}

// Work Sans Light
@mixin font-light {
  font-family: work-sans, sans-serif;
  font-weight: 300;
  font-style: normal;
}

// Work Sans Regular
@mixin font-regular {
  font-family: work-sans, sans-serif;
  font-weight: 400;
  font-style: normal;
}

// Work Sans Medium
@mixin font-medium {
  font-family: work-sans, sans-serif;
  font-weight: 500;
  font-style: normal;
}

// Work Sans SemiBold
@mixin font-semibold {
  font-family: work-sans, sans-serif;
  font-weight: 600;
  font-style: normal;
}

// Work Sans Bold
@mixin font-bold {
  font-family: work-sans, sans-serif;
  font-weight: 700;
  font-style: normal;
}

// Work Sans ExtraBold
@mixin font-xbold {
  font-family: work-sans, sans-serif;
  font-weight: 800;
  font-style: normal;
}

// Work Sans Black
@mixin font-black {
  font-family: work-sans, sans-serif;
  font-weight: 900;
  font-style: normal;
}



// Sofia Pro Soft Medium
@mixin font-titre-medium {
  font-family: sofia-pro-soft, sans-serif;
  font-weight: 500;
  font-style: normal;
}

// Sofia Pro Soft Bold
@mixin font-titre-bold {
  font-family: sofia-pro-soft, sans-serif;
  font-weight: 700;
  font-style: normal;
}

// Sofia Pro Soft Bold Italic
@mixin font-titre-bold-ital {
  font-family: sofia-pro-soft, sans-serif;
  font-weight: 700;
  font-style: italic;
}

// Sofia Pro Soft Medium Italic
@mixin font-titre-medium-ital {
  font-family: sofia-pro-soft, sans-serif;
  font-weight: 500;
  font-style: italic;
}

// Sofia Pro Soft Light
@mixin font-titre-light {
  font-family: sofia-pro-soft, sans-serif;
  font-weight: 300;
  font-style: normal;
}

// Sofia Pro Soft Light Italic
@mixin font-titre-light-ital {
  font-family: sofia-pro-soft, sans-serif;
  font-weight: 300;
  font-style: italic;
}

// Sofia Pro Soft Regular
@mixin font-titre-regular {
// font-family: sofia-pro-soft, sans-serif;
// font-weight: 400;
// font-style: normal;
}

// Sofia Pro Soft Regular Italic
@mixin font-titre-regular-ital {
// font-family: sofia-pro-soft, sans-serif;
// font-weight: 400;
// font-style: italic;
}
