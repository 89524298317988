.template-single-article {

  .banner {
    width: 100%;
    // height: 260px;
    height: 215px;
    margin-top: 100px;
    background-size: cover;
    background-position: center bottom;
    background-repeat: no-repeat;
    @include break(mobile){
      margin-top: 80px;
    }
  }

  .breadcrumb {
    width: 100%;
    color: $white;
    @include font-medium;
    padding: 10px 0;
    a {
      text-decoration: none;
      display: inline-block;

      br {
        display: none;
      }
    }
  }

  h1{
    margin-top: 50px;
    @include break(tablet){
      font-size: 3.3rem;
    }

  }

  .content{
    display: flex;
     @include break(tablet){
       flex-direction: column;
     }

    .text {
      @include break(up-tablet){
        min-width: 650px;
        padding-right: 70px;
      }
      p {
        line-height: 1.3em;
      }

      @include break(tablet){
        padding-right: 0px;
      }
    }

    aside {
      padding-left: 30px;
      margin-top: 65px;
      border-left: 1px solid #C7BAA9;
      @include break(tablet){
        border-left: none;
        border-top: 1px solid #C7BAA9;
        margin-top: 15px;
        padding-top: 60px;
        padding-left: 0px;
        text-align: center;

        .box-faq {
          display: block;
          margin-left: auto;
          margin-right: auto;
          text-align: left;
        }
      }
      .text {
        min-width: 0px;
      }
    }

    strong {
      font-size: 20px;
      @include font-medium;
      display: block;
      margin-bottom: 30px;
    }
  }

  div#was-this-helpful {
    background-color: transparent;
    padding: 30px 0px;
    margin: 60px 0px;
    border-top: 1px solid #C7BAA9;
    border-radius: 0;
    text-align: left;
    display: block;
    @include break(tablet){
      margin-bottom: 0;
    }

    div#wthf-title {
      color: $green;
      @include font-titre-medium;
      font-size: 1.52em;
      padding-bottom: 50px;
    }

    #wthf-yes-no span {
      background-color: transparent;
      box-shadow: none;
      border: 2px solid $green;
      color: $green;
      border-radius: 40px;
      font-size: 1.25em;
      @include font-titre-bold;
      line-height: 0em;
    }

    #wthf-yes-no span:first-child {
      margin-right: 35px;
    }

    #wthf-yes-no span:last-child {
      margin-left: 0px;
    }
  }

  .btn.mobile {
    width: 225px;
    margin-bottom: 35px;
  }

  .section5 {
    background-image: url(../img/home_bg.jpg);
    background-size: cover;
    padding: 150px 5% 250px 5%;
    text-align: center;
    background-position: center;

    h2 {
      font-size: 3.75em;
      padding-bottom: 60px;
      padding-top: 60px;
      @include break(mobile){
        font-size: 2.25em;
      }
    }
  }
}
